import { useLocation } from '@reach/router';
import { Container, Pagination } from 'components';
import { SEO } from 'components/SEO';
import { PageProps } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { ClusterDescription, Product } from 'models';
import { Breadcrumbs, setFitlerUrl } from 'modules/navigation';
import {
  DesktopRoofWindowFilters,
  filterByModel,
  MobileRoofWindowFilters,
  ProductListing,
  sortAlphabetically,
  usePagination,
} from 'modules/products';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { listing } from 'styles';
import { createMarkup, localizeNavCategory } from 'utils';

interface Props {
  products: Product[];
  models: string[];
  description: ClusterDescription;
}

export interface RoofWindowsState {
  models: {
    [key: string]: boolean;
  };
}

const INITIAL_FILTER_STATE = {
  models: {},
};

const AllRoofWindowsListing: React.FC<PageProps<unknown, Props>> = ({
  pageContext: { products, models, description },
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const queryParse = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [state, setState] = useState<RoofWindowsState>(INITIAL_FILTER_STATE);
  const [filteredProducts, setFilteredProducts] = useState([...products]);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const { p: currentLocationPage = 1, model } = queryParse;
  const { next, prev, jump, currentData, currentPage, maxPage } =
    usePagination<Product>(
      filteredProducts,
      itemsPerPage,
      Number(currentLocationPage),
    );

  const {
    description: clusterDescription,
    metaDescription,
    bullets,
  } = description;

  useEffect(() => {
    const filteredModelsProducts = filterByModel(state.models, products);

    const sortedProducts = sortAlphabetically<Product>(filteredModelsProducts);

    setFilteredProducts(sortedProducts);
  }, [state, products, location.pathname]);

  useEffect(() => {
    if (model) {
      const modelsString = model as string;
      const models = modelsString.split('-');

      models.forEach((model: string) => {
        handleChange(model, true, 'models');
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    setFitlerUrl(state, currentPage);
  }, [currentPage, state, state.models]);

  return (
    <Container>
      <SEO
        productName={formatMessage({
          id: 'template.all-window-listing.seo.product-name',
        })}
        productDescription={metaDescription}
      />
      <Breadcrumbs
        navigationCategory={localizeNavCategory(
          products[0].commercial.navigationCategories.code,
        )}
        category={formatMessage({ id: 'shared.breadcrumbs.all-products' })}
      />

      <div css={listing.flex}>
        <DesktopRoofWindowFilters
          amount={products.length}
          models={models}
          handleChange={handleChange}
          clearFilters={clearFilters}
          filterState={state}
        />
        <section css={listing.listSection}>
          <MobileRoofWindowFilters
            amount={products.length}
            models={models}
            handleChange={handleChange}
            clearFilters={clearFilters}
            filterState={state}
          />
          {clusterDescription && (
            <div css={listing.descriptionWrapper}>
              <h2 css={listing.pageTitle}>
                <FormattedMessage id="template.all-window-listing.title" />
              </h2>
              <div css={listing.descriptionContent}>
                <p
                  dangerouslySetInnerHTML={createMarkup(clusterDescription)}
                  css={listing.pageDescription}
                />
                <ul css={listing.bulletsWrapper}>
                  {bullets?.map((bullet) => (
                    <li key={bullet} css={listing.pageDescription}>
                      {bullet}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <ProductListing products={currentData} />
          {filteredProducts.length > 12 && (
            <Pagination
              currentPage={Number(currentLocationPage)}
              next={next}
              prev={prev}
              jump={jump}
              maxPage={maxPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          )}
        </section>
      </div>
    </Container>
  );

  function handleChange(name: string, value: boolean, section?: string) {
    if (!section) return;

    setState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [name]: value,
      },
    }));
    jump(1);
  }

  function clearFilters() {
    setState(INITIAL_FILTER_STATE);
    jump(1);
  }
};

export default AllRoofWindowsListing;
